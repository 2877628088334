import * as R from "ramda";
import {
  ALL_SYM,
  ClassicAccount,
  ClientAuthArgs,
  GlobalContext,
  SemarAccounts,
} from "../shared/ichibotrpc_types";
import {
  exhaustiveCheck,
  getErrorMessage,
  isReplacementDefinition,
} from "../shared/util";
import ClientManager from "./clientmanager";
import {
  CONSOLE_COLORS,
  DEBUG_INTERFACE_PREFIX,
  SETTINGS_KEY,
  TOOLS_INTERFACE_PREFIX,
} from "./constants";
import { IchibotClientOpts } from "./ichibotclient";
import {
  deleteAuth,
  deleteAuthByName,
  ensureClientId,
  ensureClientSettings,
  getAuthFromStore,
  getPromptText,
  isClassic,
  refreshPrompt,
  saveAuth,
  startupGetAuthFromStore,
} from "./methods";
import QueryManager from "./querymanager";
import { RawCmdResolver, RawCmdResult } from "./rawcmdresolver";
import { nanoid } from "nanoid/async";
import {
  ClientSettingNames,
  ClientSettings,
  ClientSettingsBoolean,
  ClientSettingsBooleans,
  ClientSettingsLoginMode,
  ClientSettingsLoginModes,
} from "./types";

const COL = CONSOLE_COLORS;

interface authSidekick {
  abortC: AbortController;
  keys: string[];
  delays: number[];
  commandCounter: number;
  rawCmdResolver: RawCmdResolver;
}

type MasterClientInternals = {
  opts: IchibotClientOpts;
  clientId: string;
  clientSettings: ClientSettings;
  auth: (ClientAuthArgs & authSidekick) | null;
  lastAuth: ClientAuthArgs | null;
  wrappedContext: { context: GlobalContext | null | false };
  queryMan: QueryManager;
  clientMan: ClientManager;
};

export default class MasterClient {
  private internals: MasterClientInternals;
  constructor(opts: IchibotClientOpts) {
    const clientId = ensureClientId(opts);
    const clientSettings = ensureClientSettings(opts);
    const wrappedContext = { context: null };
    this.internals = {
      opts,
      clientId,
      clientSettings,
      auth: null,
      lastAuth: null,
      wrappedContext,
      queryMan: new QueryManager(opts),
      clientMan: new ClientManager(opts, clientId, wrappedContext),
    };
    const auth = startupGetAuthFromStore(opts, opts.startWithFriendlyName);
    this.applyAuth(auth);
  }
  private reloadSettings() {
    const settings = ensureClientSettings(this.internals.opts);
    this.internals.clientSettings = settings;
    return settings;
  }
  private clearAuth(skipBye?: boolean): Promise<(boolean | null)[]> {
    const sBye =
      skipBye !== undefined
        ? skipBye
        : this.internals.clientSettings.loginMode === "stay-running";
    const { internals } = this;
    let p: Promise<(boolean | null)[]> = Promise.resolve([]);
    if (internals.auth) {
      p = internals.clientMan.clearAuth(sBye);
      internals.lastAuth = internals.auth;
    }
    internals.auth = null;
    internals.wrappedContext.context = false;
    return p;
  }
  // return true if auth is set successfully
  private applyAuth(auth: ClientAuthArgs | null): boolean {
    const { internals } = this;
    if (auth === null) {
      this.clearAuth();
      return false;
    } else if (isClassic(auth)) {
      const cAuth = auth as ClassicAccount;
      const { exchange, apiKey, subAccount } = cAuth;
      const keys = [ClientManager.generateKey(exchange, apiKey, subAccount)];
      const delays = [0];
      internals.auth = R.mergeRight(cAuth, {
        abortC: new AbortController(),
        keys,
        delays,
        commandCounter: 1,
        rawCmdResolver: new RawCmdResolver(internals.opts, cAuth),
      });
      internals.wrappedContext.context = null;
      return true;
    } else if (auth.mode === "semar") {
      const sAuth = auth as SemarAccounts;
      const keys = sAuth.accounts.map((acc) => {
        return ClientManager.generateKey(
          sAuth.exchange,
          acc.apiKey,
          acc.subAccount
        );
      });
      const delays = sAuth.accounts.map((acc) => {
        const { delay } = acc;
        if (typeof delay === "number" && isFinite(delay)) {
          return delay;
        }
        return 0;
      });
      internals.auth = R.mergeRight(sAuth, {
        abortC: new AbortController(),
        keys,
        delays,
        commandCounter: 1,
        rawCmdResolver: new RawCmdResolver(internals.opts, sAuth),
      });
      internals.wrappedContext.context = null;
      return true;
    } else {
      this.internals.opts.logger.error(`Mode ${auth.mode} is not supported`);
      return false;
    }
  }
  private async processCommand(cmd: string): Promise<RawCmdResult[]> {
    const { internals } = this;
    const {
      auth,
      wrappedContext: { context },
    } = internals;
    const output = internals.opts.logger;
    const [a, b, ...rest] = cmd.split(/\s+/).map((p) => p.trim());
    if (a.startsWith(DEBUG_INTERFACE_PREFIX)) {
      const a1 = a.substring(DEBUG_INTERFACE_PREFIX.length);
      const def = () => {
        output.log(`Debug interface`);
        output.dir({
          "Last command status": `${DEBUG_INTERFACE_PREFIX}0`,
          "Prev command status": `${DEBUG_INTERFACE_PREFIX}- or ${DEBUG_INTERFACE_PREFIX}-1`,
          "Prev {n} command status": `${DEBUG_INTERFACE_PREFIX}-{n}, e.g. ${DEBUG_INTERFACE_PREFIX}-2`,
          "Command status #{n}": `${DEBUG_INTERFACE_PREFIX}{n}`,
          "Example, for command 23": `${DEBUG_INTERFACE_PREFIX}23`,
          "Example, for command 123456": `${DEBUG_INTERFACE_PREFIX}3456`,
        });
        return [{ success: true }];
      };
      switch (a1[0]) {
        case undefined: {
          return def();
        }
        case "0": {
          if (!auth) {
            output.log(`Cannot see last command when no account is loaded`);
            return [{ success: true }];
          }
          const cmdN = auth.commandCounter - 1;
          if (cmdN > 0) {
            auth.rawCmdResolver.checkStatus(cmdN.toString());
          } else {
            output.log(`No commands yet`);
          }
          return [{ success: true }];
        }
        case "-": {
          let prev = parseInt(a1);
          if (isNaN(prev)) {
            prev = -1;
          }
          if (!auth) {
            output.log(
              `Cannot see previous commands when no account is loaded`
            );
            return [{ success: true }];
          }
          const lastCmdN = auth.commandCounter - 1;
          const cmdN = lastCmdN + prev;
          if (cmdN > 0) {
            auth.rawCmdResolver.checkStatus(cmdN.toString());
          } else if (lastCmdN > 0) {
            output.log(`Only ${lastCmdN} commands tracked for this login`);
          } else {
            output.log(`No commands yet`);
          }
          return [{ success: true }];
        }
        default: {
          const num = parseInt(a1);
          if (isNaN(num)) {
            return def();
          }
          if (!auth) {
            output.log(`Cannot see command status when no account is loaded`);
            return [{ success: true }];
          }
          if (num < auth.commandCounter) {
            auth.rawCmdResolver.checkStatus(a1);
          } else {
            const lastCmdN = auth.commandCounter - 1;
            if (lastCmdN > 0) {
              output.log(`Only ${lastCmdN} commands tracked for this login`);
            } else {
              output.log(`No commands yet`);
            }
          }
          return [{ success: true }];
        }
      }
    }
    if (a.startsWith(TOOLS_INTERFACE_PREFIX)) {
      const def = () => {
        output.log(`Tools interface`);
        output.dir({
          "Client settings": `${TOOLS_INTERFACE_PREFIX}settings`,
          "Connection status": `${TOOLS_INTERFACE_PREFIX}status`,
          "Context reset to global *": `${TOOLS_INTERFACE_PREFIX}reset`,
          "Drop connection(s) (stay running)": `${TOOLS_INTERFACE_PREFIX}stay`,
          "Reset connection(s)": `${TOOLS_INTERFACE_PREFIX}reconnect`,
          Quit: `${TOOLS_INTERFACE_PREFIX}quit`,
        });
        return [{ success: true }];
      };
      const a1 = a.substring(TOOLS_INTERFACE_PREFIX.length).toLowerCase();
      const a2 = a1.substring(0, 2);
      switch (a2) {
        case "se": {
          if (a1 !== `settings`) {
            def();
            return [
              {
                success: false,
                message: `Invalid command ${a}, did you mean ${TOOLS_INTERFACE_PREFIX}settings?`,
              },
            ];
          }
          const settings = this.reloadSettings();
          const settingName: string | undefined = b?.toLowerCase();
          const validIndex = ClientSettingNames.findIndex((v) => {
            return v.toLowerCase() === settingName;
          });
          if (validIndex > -1) {
            const n = ClientSettingNames[validIndex];
            const newSetting: string | undefined = rest[0]?.toLowerCase();
            switch (n) {
              case "loginMode": {
                if (newSetting === undefined) {
                  output.log(
                    `Current setting for loginMode: ${settings.loginMode}\n` +
                      ` Options: ${ClientSettingsLoginModes}`
                  );
                  return [{ success: true }];
                }
                const validMode = ClientSettingsLoginModes.includes(
                  newSetting as ClientSettingsLoginMode
                );
                if (validMode) {
                  settings.loginMode = newSetting as ClientSettingsLoginMode;
                  this.internals.opts.clientDB.push(
                    `/${SETTINGS_KEY}`,
                    settings
                  );
                  output.log(`New setting for loginMode: ${newSetting}`);
                  this.reloadSettings();
                  return [{ success: true }];
                }
                output.warn(
                  `Invalid setting for loginMode: ${newSetting}\n` +
                    ` Options: ${ClientSettingsLoginModes}`
                );
                return [{ success: true }];
              }
              case "logTimestamps": {
                if (newSetting === undefined) {
                  output.log(
                    `Current setting for logTimestamps: ${settings.logTimestamps}\n` +
                      ` Options: ${ClientSettingsBooleans}`
                  );
                  return [{ success: true }];
                }
                let setting: boolean;
                switch (newSetting as ClientSettingsBoolean) {
                  case "true": {
                    setting = true;
                    break;
                  }
                  case "false": {
                    setting = false;
                    break;
                  }
                  default: {
                    output.warn(
                      `Invalid setting for logTimestamps: ${newSetting}\n` +
                        ` Options: ${ClientSettingsBooleans}`
                    );
                    return [{ success: true }];
                  }
                }
                settings.logTimestamps = setting;
                this.internals.opts.clientDB.push(`/${SETTINGS_KEY}`, settings);
                output.log(`New setting for logTerminate: ${setting}`);
                this.reloadSettings();
                return [{ success: true }];
              }
              default: {
                exhaustiveCheck(n);
              }
            }
          }
          if (settingName !== undefined) {
            output.warn(`Invalid setting ${settingName}`);
            return [{ success: true }];
          }

          const out = {
            "SETTING NAME": "current setting [options]",
            loginMode: `${settings.loginMode} [${ClientSettingsLoginModes}]`,
            logTimestamps: `${settings.logTimestamps} [${ClientSettingsBooleans}]`,
          };
          output.dir(out);
          output.log(
            `Example command: "${TOOLS_INTERFACE_PREFIX}settings loginmode stay-running"`
          );
          return [{ success: true }];
        }
        case "st": {
          if (a1 === `status`) {
            const [result, debugResult] =
              await this.internals.clientMan.getStatus();
            if (result) {
              output.log(result);
              debugResult && output.debug(debugResult);
              return [{ success: true }];
            }
            if (debugResult) {
              output.log(debugResult);
              return [{ success: true }];
            }
            output.log(`Sorry, unable to provide any connection information`);
            return [{ success: true }];
          }
          if (a1 === `stay`) {
            const result = await this.clearAuth(true);
            const nComplete = result.filter((x) => x === true).length;
            output.log(`Dropped ${nComplete} connections`);
            return [{ success: true }];
          }
          def();
          return [
            {
              success: false,
              message: `Invalid command ${a}, did you mean ${TOOLS_INTERFACE_PREFIX}status or ${TOOLS_INTERFACE_PREFIX}stay?`,
            },
          ];
        }
        case "qu": {
          if (a1 !== `quit`) {
            def();
            return [
              {
                success: false,
                message: `Invalid command ${a}, did you mean ${TOOLS_INTERFACE_PREFIX}quit?`,
              },
            ];
          }
          const result = await this.clearAuth(false);
          const nComplete = result.filter((x) => x === true).length;
          output.log(`Closed ${nComplete} connections`);
          return [{ success: true }];
        }
        case "re": {
          if (a1 === "reconnect") {
            let targetAuth: ClientAuthArgs | null = auth;
            if (!targetAuth) {
              if (internals.lastAuth === null) {
                output.warn(`Not logged in`);
              } else {
                output.warn(
                  `Will reconnect to ${internals.lastAuth.friendlyName}`
                );
                targetAuth = internals.lastAuth;
              }
            }
            const result = await this.clearAuth(true);
            const nComplete = result.filter((x) => x === true).length;
            auth !== null && output.log(`Dropped ${nComplete} connections`);
            const currentAuth = internals.auth;
            if (currentAuth !== null) {
              output.warn(
                `Another login was loaded while waiting for connections to drop. Aborting connection reset.`
              );
              return [{ success: true }];
            }
            const ok = this.applyAuth(targetAuth);
            if (ok) {
              internals.auth && this.connect(internals.auth);
              return [{ success: true }];
            }
            output.warn(`Connection reset failed.`);
            return [{ success: true }];
          }
          if (a1 === "reset") {
            if (!auth) {
              output.warn(`Not logged in`);
              return [{ success: true }];
            }
            output.log(`Resetting instrument to *`);
            internals.clientMan.resetContext(auth.keys);
            return [{ success: true }];
          }
          def();
          return [
            {
              success: false,
              message: `Invalid command ${a}, did you mean ${TOOLS_INTERFACE_PREFIX}reset or ${TOOLS_INTERFACE_PREFIX}reconnect?`,
            },
          ];
        }
        default: {
          return def();
        }
      }
    }
    if (a.startsWith("#")) {
      return [{ success: true }];
    }
    if (["exit", "quit", "q"].includes(a)) {
      await this.handleQuit();
      return [{ success: true }];
    }
    if (a === "login") {
      this.clearAuth();
      if (b) {
        const auth = getAuthFromStore(internals.opts, b);
        if (auth) {
          const ok = this.applyAuth(auth);
          ok && internals.auth && (await this.connect(internals.auth));
          return [{ success: true }];
        }
        output.warn(`No login found for ${b}`);
        return [{ success: true }];
      }
      const auth = await this.internals.queryMan.requestLogin();
      const ok = this.applyAuth(auth);
      if (ok) {
        saveAuth(internals.opts, auth);
        output.log(
          'API key(s) saved. To clear current credentials please type "logout".'
        );
        internals.auth && (await this.connect(internals.auth));
      }
      return [{ success: true }];
    } else {
      if (!auth) {
        output.log(`No account loaded. Please type 'login' to start.`);
        return [{ success: true }];
      }
    }
    if (!auth || auth.keys.length < 1) {
      throw new Error(`No auth present. This should not have happened.`);
    }
    if (a === "logout") {
      if (b) {
        if (auth.friendlyName !== b) {
          output.log(`Clearing your credentials for ${b}...`);
          deleteAuthByName(internals.opts, b);
          return [{ success: true }];
        }
      }
      output.log(`Signing out...`);
      const p = this.clearAuth(false);
      output.log(`Clearing your credentials for ${auth.friendlyName}...`);
      deleteAuth(internals.opts, auth);
      await p;
      output.log("Done.");
      return [{ success: true }];
    }
    if (!context) {
      output.warn(`Not ready. Cannot execute command yet.`);
      return [{ success: false }];
    }

    if (a === "webidgen") {
      const webId = (await nanoid(24)).toLowerCase();
      cmd = `webid ${webId}`;
    }

    const cmdNum = auth.commandCounter++;
    const r = internals.clientMan.callRpc(
      auth.keys,
      auth.delays,
      cmdNum,
      "rawcmd",
      { cmd, context, debug: this.internals.opts.debug }
    );

    const rr = r.map((v, i) => {
      return v.then((val) => {
        if (typeof val === "string") {
          return Promise.resolve({ success: false, message: val });
        }
        if (!val) {
          const message = `Could not send a request [${cmdNum}] to Ichibot server for ${auth.keys[i]}: client error (this should not happen)`;
          return Promise.resolve({ success: false, message });
        }
        return val;
      });
    });

    const currentInstrument = context.currentInstrument;

    return auth.rawCmdResolver.resolve(cmdNum, context, cmd, rr).then((res) => {
      let anySuccess = false;
      for (const re of res) {
        if (re.success) {
          anySuccess = true;
          if (re.message) {
            internals.opts.logger.log(re.message);
          }
        }
      }
      if (anySuccess) {
        // prettier-ignore
        if (a === "alias" && rest.length > 0) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument ?? ALL_SYM, [a, b, null], cmd);
        } else if (isReplacementDefinition(cmd)) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument ?? ALL_SYM, [a, b.replace(":", "")], cmd);
        } else if (a === "fatfinger" && b && currentInstrument !== null) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument, [a], cmd);
        } else if (a === "fatfinger-quote" && b && currentInstrument !== null) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument, [a], cmd);
        } else if ((a === "set" || a === "sets") && /^[a-zA-Z0-9]+$/.test(b) && rest.length === 1) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument ?? ALL_SYM, [a, b], cmd);
        } else if (a === "unalias" && b && rest.length === 0) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument ?? ALL_SYM, ["alias", b, null], null, false, true);
        } else if (a === "unreplace" && rest.length === 0) {
          internals.opts.saveCmdToInit(auth.exchange, currentInstrument ?? ALL_SYM, ["replace", b, null], null);
        } else if (a === "keep-alive" && /^[0-9]+$/.test(b)) {
          internals.opts.saveCmdToInit(auth.exchange, ALL_SYM, [a], cmd, true);
        } else if (a === "webidgen") {
          internals.opts.saveCmdToInit(auth.exchange, ALL_SYM, ["webid"], null, false, true);
          internals.opts.saveCmdToInit(auth.exchange, ALL_SYM, ["webid"], cmd, true);
        }
      }
      return res;
    });
  }
  private async connect(auth: ClientAuthArgs) {
    const { internals } = this;
    internals.clientMan.setAuth(auth);
  }
  async start() {
    const { internals } = this;
    const output = internals.opts.logger;
    refreshPrompt(internals.opts, internals.wrappedContext.context);
    if (!internals.auth) {
      output.log(`No account loaded. Please type login to start.`);
    } else {
      await this.connect(internals.auth);
    }

    const done = false;

    while (!done) {
      try {
        const answer = (
          await internals.queryMan.query(
            getPromptText(internals.wrappedContext.context)
          )
        ).trim();

        output.log(COL.Dim, ":::", answer);
        if (answer.length > 0) {
          const chooseNoun = (n: number) => (n > 1 ? "errors" : "error");
          const result = (async () => {
            try {
              const rawResults = await this.processCommand(answer);
              const errors = rawResults.filter((rw) => !rw.success);
              if (errors.length > 0) {
                let msg = `${errors.length} ${chooseNoun(
                  errors.length
                )} processing "${answer}"`;
                let errMsg = "";
                let unspec = 0;
                for (let i = 0; i < errors.length; i++) {
                  const error = errors[i];
                  if (error.message) {
                    errMsg += `\n${i + 1}. ${error.message}`;
                  } else {
                    unspec++;
                  }
                }
                if (unspec > 0) {
                  msg += ` (${unspec} unspecified ${chooseNoun(unspec)})`;
                }
                msg += errMsg;
                output.error(msg);
              }
            } catch (err) {
              output.error(
                `Error processing the command:`,
                getErrorMessage(err)
              );
            }
            output.debug(`Command ${answer.split(" ")[0]} completed.`);
          })();

          if (answer.startsWith("login")) {
            // Login is special using query to get answers. Can't start getting new queries in this loop before it's done.
            await result;
          }
        }
      } catch (err: unknown) {
        output.error(
          `Unexpected error: ${(err as { message: string })?.message}`
        );
      }
    }
  }
  sendInput(msg: string) {
    this.internals.queryMan.sendInput(msg);
  }
  async handleQuit() {
    await this.internals.clientMan.clearAuth();
    this.internals.opts.process.exit(0);
  }
}
