import { Waterfall } from "hydrated-ws";
import WebSocket from "isomorphic-ws";

type HeartbreakerInternals = {
  waterfall: Waterfall;
  pingMs: number;
  msg?: string;
  resetMs: number;
  lastMs: number;
  pingTimer?: NodeJS.Timeout;
  checkTimer?: NodeJS.Timeout;
  openListener: () => void;
};

export class Heartbreaker {
  private internals: HeartbreakerInternals;
  constructor(
    waterfall: Waterfall,
    pingMs: number,
    resetMs: number,
    msg?: string
  ) {
    const internals: HeartbreakerInternals = {
      waterfall,
      pingMs,
      msg,
      resetMs,
      lastMs: Date.now(),
      pingTimer: undefined,
      checkTimer: undefined,
      openListener: () => {
        // do nothing
      },
    };
    const ping =
      msg === undefined
        ? () => {
            if (internals.waterfall.readyState !== WebSocket.OPEN) {
              internals.lastMs = Date.now();
            }
          }
        : () => {
            if (internals.waterfall.readyState === WebSocket.OPEN) {
              try {
                internals.waterfall.send(internals.msg ?? "");
              } catch (e) {
                // continue
              }
              return;
            }
            internals.lastMs = Date.now();
          };
    const check = () => {
      setImmediate(() => {
        if (Date.now() > internals.lastMs + resetMs) {
          this.reset();
        }
      });
    };
    internals.openListener = () => {
      internals.pingTimer && clearInterval(internals.pingTimer);
      internals.checkTimer && clearInterval(internals.checkTimer);
      internals.pingTimer = setInterval(ping, pingMs);
      internals.checkTimer = setInterval(check, resetMs);
    };
    internals.waterfall.addEventListener("open", internals.openListener);
    internals.openListener();
    this.internals = internals;
  }
  public pong() {
    this.internals.lastMs = Date.now();
  }
  public reset() {
    const { internals } = this;
    internals.waterfall.reset();
    internals.pingTimer && clearInterval(internals.pingTimer);
    internals.checkTimer && clearInterval(internals.checkTimer);
  }
  public close() {
    const { internals } = this;
    internals.waterfall.removeEventListener("open", internals.openListener);
    internals.pingTimer && clearInterval(internals.pingTimer);
    internals.checkTimer && clearInterval(internals.checkTimer);
  }
}
