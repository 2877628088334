import { Cable, exponentialTruncatedBackoff, Waterfall } from "hydrated-ws";
import { EventEmitter } from "events";
import {
  AuthRecord,
  ClassicAccount,
  ContextNotification,
  GlobalContext,
  IchibotRPC,
  InstructionNotification,
  MessageNotification,
} from "../shared/ichibotrpc_types";
import { IchibotClientOpts } from "./ichibotclient";
import {
  generateWsHeaders,
  generateWsUrl,
  getCookieSaveKey,
  noop,
} from "./methods";
import WebSocket from "isomorphic-ws";
import {
  CLIENT_STATUS,
  CONTEXT_UPDATE,
  IS_NODEJS,
  POKE_INTERVAL,
  WEBSOCKET_CLOSE,
  WEBSOCKET_ERROR,
  WEBSOCKET_OPEN,
} from "./constants";
// @ts-ignore - not available in web
import type { IncomingMessage } from "node:http";
import { Heartbreaker } from "../shared/heartbreaker";
import { exhaustiveCheck, isObject, ParameterType } from "../shared/util";
import { ConnectionStatus } from "./types";

const CLOSING_UPDATE: Partial<ConnectionStatus> = {
  network: "closing",
};

const CLOSED_UPDATE: Partial<ConnectionStatus> = {
  network: "closed",
};

type IchibotSingleInternals = {
  clientId: string;
  emitter: IchibotSingleEmitter;
  ws: Waterfall;
  hb: Heartbreaker;
  internalWs: WebSocket | null;
  cable: Cable;
  webPingFunction: () => Promise<void>;
  webPingInterval: NodeJS.Timer | null;
  account: ClassicAccount;
  auth: AuthRecord;
  context: GlobalContext | null;
  opts: IchibotClientOpts;
  intendedClose: number; // timestamp
  intendedBye: number; // timestamp
};

interface IEmitter extends EventEmitter {
  on(event: string, listener: (...args: unknown[]) => unknown): this;
  on(event: symbol, listener: (...args: unknown[]) => unknown): this;
  on(event: typeof WEBSOCKET_ERROR, listener: (ev: Event) => void): this;
  on(event: typeof WEBSOCKET_OPEN, listener: (ev: Event) => void): this;
  on(event: typeof WEBSOCKET_CLOSE, listener: (ev: CloseEvent) => void): this;
  on(
    event: typeof CLIENT_STATUS,
    listener: (update: Partial<ConnectionStatus>) => void
  ): this;
  on<T = typeof CONTEXT_UPDATE>(
    event: T,
    listener: (context: ContextNotification["params"]) => void
  ): this;
  emit<T = typeof WEBSOCKET_ERROR>(event: T, ev: Event): boolean;
  emit<T = typeof WEBSOCKET_OPEN>(event: T, ev: Event): boolean;
  emit<T = typeof WEBSOCKET_CLOSE>(event: T, ev: CloseEvent): boolean;
  emit<T = typeof CLIENT_STATUS>(
    event: T,
    update: Partial<ConnectionStatus>
  ): boolean;
  emit<T = typeof CONTEXT_UPDATE>(
    event: T,
    context: ContextNotification["params"]
  ): boolean;
}

export class IchibotSingleEmitter extends EventEmitter implements IEmitter {}

export class IchibotSingle {
  emitter: IchibotSingleEmitter;
  private prepDir: { localName: string };
  private prepShort: string;
  constructor(private internals: IchibotSingleInternals) {
    this.emitter = internals.emitter;
    const { friendlyName } = internals.account;
    this.prepDir = { localName: `${friendlyName}` };
    this.prepShort = `[${friendlyName.substring(0, 6)}]`;
    this.handleIchibotRPCNotification =
      this.handleIchibotRPCNotification.bind(this);
    internals.cable.register(
      "IchibotRPCNotification",
      this.handleIchibotRPCNotification
    );
    // Option to transition to a shorter method name in future
    internals.cable.register("IchiRPCNot", this.handleIchibotRPCNotification);
  }
  private async handleIchibotRPCNotification(data: unknown) {
    if (!data || typeof data !== "object") {
      this.internals.opts.logger.warn(`Invalid data received from server`);
      return;
    }
    const { notification, params } = data as {
      notification: string;
      params: unknown;
    };
    if (typeof notification !== "string") {
      this.internals.opts.logger.warn(
        `Invalid notification type received from server`
      );
      return;
    }
    switch (notification) {
      case "feed": {
        const feed = params as MessageNotification["params"];
        switch (feed.type) {
          case "dir": {
            const msg = feed.message[0];
            if (isObject(msg)) {
              this.internals.opts.logger.dir(this.prepDir, msg);
            }
            return;
          }
          case "debug": {
            if (this.internals.opts.debug) {
              this.internals.opts.logger.debug(this.prepShort, ...feed.message);
            }
            return;
          }
          case "log": {
            this.internals.opts.logger.log(this.prepShort, ...feed.message);
            return;
          }
          case "warn": {
            this.internals.opts.logger.warn(this.prepShort, ...feed.message);
            return;
          }
          case "error": {
            this.internals.opts.logger.error(this.prepShort, ...feed.message);
            return;
          }
          default: {
            this.internals.opts.logger.debug(
              `Unrecognized notification type "${feed.type}" was received from server.`
            );
            return;
          }
        }
      }
      case "context": {
        const context = params as ContextNotification["params"];
        this.internals.context = context;
        this.internals.emitter.emit(CONTEXT_UPDATE, context);
        return;
      }
      case "instruction": {
        const inst = params as InstructionNotification["params"];
        if (inst.instruction === "force-disconnect") {
          if (inst.reason) {
            this.internals.opts.logger.warn(this.prepShort, inst.reason);
          } else {
            this.internals.opts.logger.warn(
              this.prepShort,
              `The server has terminated your connection. You may connect again in this client by restarting or typing "login <name of your key>".`
            );
          }
          this.close();
        } else {
          try {
            exhaustiveCheck(inst.instruction);
          } catch (e) {
            if (this.internals.opts.debug) {
              this.internals.opts.logger.debug(`${e}`);
            }
          }
        }
      }
      // New notification types will silently fail
    }
  }
  get context() {
    return this.internals.context;
  }
  resetContext(
    arg: Omit<ParameterType<IchibotRPC["rawcmd"]>, "auth" | "cmd" | "context">
  ) {
    const { internals } = this;
    if (
      internals.ws?.readyState !== WebSocket.OPEN ||
      internals.internalWs?.readyState !== WebSocket.OPEN
    ) {
      throw new Error(
        `Could not reset context on ${internals.account.friendlyName}: client is not connected.`
      );
    }
    const args: ParameterType<IchibotRPC["rawcmd"]> = {
      cmd: "instrument *",
      auth: internals.auth,
      context: null,
      ...arg,
      clientId: internals.clientId,
    };
    return internals.cable.request("rawcmd", args) as ReturnType<
      IchibotRPC["rawcmd"]
    >;
  }
  callRpc<T extends keyof IchibotRPC>(
    cmdNum: number | null,
    method: T,
    arg: Omit<ParameterType<IchibotRPC[T]>, "auth">
  ): ReturnType<IchibotRPC[T]> {
    const { internals } = this;
    const cmdId = cmdNum ?? method;
    if (
      internals.ws?.readyState !== WebSocket.OPEN ||
      internals.internalWs?.readyState !== WebSocket.OPEN
    ) {
      throw new Error(
        `Could not send a request [${cmdId}] to ${internals.account.friendlyName}: client is not connected.`
      );
    }
    return internals.cable.request(method, {
      auth: internals.auth,
      ...arg,
      clientId: internals.clientId,
    }) as ReturnType<IchibotRPC[T]>;
  }
  private _close() {
    const { internals } = this;
    if (internals.webPingInterval) {
      clearInterval(internals.webPingInterval);
    }
    internals.cable.destroy();
    if (internals.ws.readyState !== WebSocket.CLOSED) {
      const updateClosed = () => {
        internals.emitter.emit(CLIENT_STATUS, CLOSED_UPDATE);
        internals.ws.removeEventListener("close", updateClosed);
      };
      internals.ws.addEventListener("close", updateClosed);
    } else {
      internals.emitter.emit(CLIENT_STATUS, CLOSED_UPDATE);
    }
    internals.ws.close();
    internals.hb.close();
  }
  private _markClosing() {
    const { internals } = this;
    internals.intendedClose = Date.now(); // truthy even with force-disconnect
    internals.emitter.emit(CLIENT_STATUS, CLOSING_UPDATE);
  }
  close() {
    this._markClosing();
    this._close();
  }
  bye(): Promise<boolean | null> {
    const { internals } = this;
    if (internals.intendedBye || internals.intendedClose) {
      return Promise.resolve(null);
    }
    internals.intendedBye = Date.now();
    this._markClosing();
    if (
      internals.ws.readyState !== WebSocket.OPEN ||
      internals.internalWs?.readyState !== WebSocket.OPEN
    ) {
      this._close();
      return IchibotSingle.connectForByeOnly(
        internals.opts,
        internals.clientId,
        internals.account,
        internals.auth
      );
    }
    try {
      internals.cable.notify("bye", {
        auth: internals.auth,
        clientId: internals.clientId,
      });
      this._close();
      return Promise.resolve(true);
    } catch (e) {
      this._close();
      return Promise.reject(e);
    }
    // delete cookie ?
  }
  static connectForByeOnly(
    opts: IchibotClientOpts,
    clientId: string,
    account: ClassicAccount,
    auth: AuthRecord
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        let success = false;
        const wsUrl = generateWsUrl(opts, account.exchange);
        const ws = new WebSocket(wsUrl, {
          perMessageDeflate: false,
          headers: generateWsHeaders(
            opts,
            account.exchange,
            account.friendlyName
          ),
        });
        const onOpen = () => {
          ws.send(
            JSON.stringify({
              jsonrpc: "2.0",
              method: "bye",
              params: {
                auth,
                clientId,
              },
            }),
            (err: Error | undefined) => {
              if (!err) {
                success = true;
                ws.close();
                return;
              }
              reject(err);
              ws.close();
            }
          );
        };
        const onClose = () => {
          ws.removeAllListeners();
          resolve(success);
        };
        ws.addEventListener("open", onOpen);
        ws.addEventListener("close", onClose);
        ws.addEventListener("error", noop);
      } catch (e) {
        reject(e);
      }
    });
  }
  static async start(
    opts: IchibotClientOpts,
    clientId: string,
    account: ClassicAccount,
    auth: AuthRecord,
    emitter: IchibotSingleEmitter
  ): Promise<IchibotSingleInternals> {
    const wrappedInternals: { internals: IchibotSingleInternals } = {} as {
      internals: IchibotSingleInternals;
    };
    const wsUrl = generateWsUrl(opts, auth.exchange);
    const ws = new Waterfall(wsUrl, undefined, {
      connectionTimeout: 5000,
      emitClose: true,
      retryPolicy: exponentialTruncatedBackoff(100, 8),
      // @ts-ignore - dispatchEvent not implemented
      factory: (url, protocols) => {
        const _ws: WebSocket = new WebSocket(url, protocols, {
          perMessageDeflate: false,
          headers: generateWsHeaders(opts, auth.exchange, auth.friendlyName),
        });
        IS_NODEJS &&
          _ws.on("upgrade", (res: IncomingMessage) => {
            if (res.headers["set-cookie"]) {
              const newCookies = res.headers["set-cookie"]
                .map((s: string) => s.split(";")[0])
                .join("; ");
              opts.clientDB.push(
                getCookieSaveKey(_ws.url, auth.friendlyName),
                newCookies
              );
            }
          });
        IS_NODEJS &&
          (function () {
            _ws.on("pong", () => {
              wrappedInternals.internals.hb.pong();
            });
            const pingInterval = setInterval(() => {
              if (_ws.readyState === WebSocket.OPEN) {
                _ws.ping(() => {
                  // do nothing
                });
              }
            }, POKE_INTERVAL);
            _ws.on("close", () => {
              clearInterval(pingInterval);
            });
          })();
        !IS_NODEJS &&
          (function () {
            if (wrappedInternals.internals.webPingInterval !== null) {
              clearInterval(wrappedInternals.internals.webPingInterval);
            }
            wrappedInternals.internals.webPingInterval = setInterval(
              wrappedInternals.internals.webPingFunction,
              POKE_INTERVAL
            );
          })();
        if (wrappedInternals.internals === undefined) {
          process.nextTick(() => {
            wrappedInternals.internals.internalWs = _ws;
          });
        } else {
          wrappedInternals.internals.internalWs = _ws;
        }
        return _ws;
      },
    });
    wrappedInternals.internals = {
      clientId,
      emitter,
      ws,
      hb: new Heartbreaker(ws, POKE_INTERVAL, POKE_INTERVAL * 3),
      internalWs: null,
      cable: new Cable(ws, {
        receiveBinary: opts.binaryDecoder,
      }),
      webPingFunction: IS_NODEJS
        ? async () => {
            // do nothing
          }
        : async () => {
            try {
              const { internalWs } = wrappedInternals.internals;
              if (
                wrappedInternals.internals.cable.readyState ===
                  WebSocket.OPEN &&
                internalWs?.readyState === WebSocket.OPEN
              ) {
                await wrappedInternals.internals.cable.request(
                  "poke",
                  {},
                  POKE_INTERVAL * 3
                );
                const currentInternalWs = wrappedInternals.internals.internalWs;
                if (internalWs === currentInternalWs) {
                  wrappedInternals.internals.hb.pong();
                }
              }
            } catch (e) {
              console.error(e);
            }
          },
      webPingInterval: null,
      account,
      auth,
      context: null,
      opts,
      intendedClose: 0,
      intendedBye: 0,
    };
    ws.onerror = (ev) => {
      emitter.emit(WEBSOCKET_ERROR, ev);
    };
    ws.onopen = (ev) => {
      emitter.emit(WEBSOCKET_OPEN, ev);
    };
    ws.onclose = (ev) => {
      if (wrappedInternals.internals.webPingInterval !== null) {
        clearInterval(wrappedInternals.internals.webPingInterval);
      }
      emitter.emit(WEBSOCKET_CLOSE, ev);
    };
    return wrappedInternals.internals;
  }
}
