import { AuthRecord, ClassicAccount } from "../shared/ichibotrpc_types";
import { IchibotSingle, IchibotSingleEmitter } from "./singleclient";

// all setting values should be lower case
export const ClientSettingsLoginModes = ["terminate", "stay-running"] as const;

export type ClientSettingsLoginMode = (typeof ClientSettingsLoginModes)[number];

export const ClientSettingsBooleans = ["true", "false"] as const;

export type ClientSettingsBoolean = (typeof ClientSettingsBooleans)[number];

export const ClientSettingNames = ["loginMode", "logTimestamps"] as const;

export type ClientSettingName = (typeof ClientSettingNames)[number];

export type ClientSettings = {
  loginMode: ClientSettingsLoginMode;
  logTimestamps: boolean;
};

export const NETWORK_STATUS = {
  connecting: "connecting",
  connected: "connected",
  reconnecting: "reconnecting",
  closing: "closing",
  closed: "closed",
} as const;

export type ConnectionStatus = {
  network: (typeof NETWORK_STATUS)[keyof typeof NETWORK_STATUS];
  lastKnownInstanceConnected?: number; // timestamp
  lastKnownKeepAlive?: number;
};

export type Connection = {
  account: ClassicAccount;
  auth: AuthRecord;
  conn: {
    emitter: IchibotSingleEmitter;
    promise: Promise<IchibotSingle>;
    isPending: boolean;
    proceedHello: boolean;
  };
  status: ConnectionStatus;
  firstSuccess: boolean;
};
