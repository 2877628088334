export const SUPPORTED_EXCHANGES = ["binance", "binance-spot"] as const;
export type ExchangeLabel = (typeof SUPPORTED_EXCHANGES)[number];

export interface ApiKey {
  live: boolean;
  id: string;
  secret: string;
  subAccount: string;
  passphrase: string;
}
