import { ExchangeLabel } from "./types";

export const isObject = (value: unknown): value is Record<string, unknown> =>
  value !== null && typeof value === "object";
const isArray = (value: unknown): value is unknown[] => Array.isArray(value);

export function getExchangeFromApiKey(key: string): ExchangeLabel {
  if (key.length === 64) {
    return "binance";
  } else {
    throw new Error(`Could not determine exchange from api key`);
  }
}

export function dropUndef(o: { [key: string]: unknown }): {
  [key: string]: unknown;
} {
  const res: { [key: string]: unknown } = {};
  for (const k of Object.keys(o)) {
    if (o[k] !== undefined) {
      res[k] = o[k];
    }
  }
  return res;
}

export function notNull<T>(x: T | null): x is T {
  return x !== null;
}

export function requireNotUndefined<T>(x: T | undefined, msg?: string): T {
  if (x === undefined) {
    throw new Error(msg ?? "Value is not defined");
  }

  return x;
}

export function exhaustiveCheck(x: never, msg?: string): never {
  throw new Error(msg || `Unexpected type value ${x}`);
}

export function waitUntil(fn: () => boolean, interval = 10) {
  return new Promise<void>((resolve) => {
    if (fn()) {
      return resolve();
    }
    const i = setInterval(() => {
      if (fn()) {
        clearInterval(i);
        resolve();
      }
    }, interval);
  });
}

export class DefaultMap<K, T> extends Map<K, T> {
  constructor(
    private builder: () => T,
    entries?: readonly (readonly [K, T])[] | null
  ) {
    super(entries);
  }

  get(key: K): T {
    const existing = super.get(key);
    if (existing) {
      return existing;
    } else {
      const item = this.builder();
      this.set(key, item);
      return item;
    }
  }
}

export const logMethod = (
  _target: Record<string, unknown>,
  propertyKey: string,
  desc: PropertyDescriptor
) => {
  const fn = desc.value;

  desc.value = function (...args: unknown[]) {
    console.log(
      `${propertyKey} called with ${args.map((x) => JSON.stringify(x))}`
    );
    const result = fn(...args);
    console.log(`${propertyKey} result ${JSON.stringify(result)}`);
    return result;
  };
};

export class RateLimit {
  private currentUtilization = 0;
  private resolveQueue: Array<() => void> = [];
  private interval: NodeJS.Timeout | null = null;

  constructor(private maxCount: number, private timeout: number) {}

  public guard(): Promise<void> {
    if (this.interval === null) {
      this.interval = setInterval(() => {
        const nextBatch = this.resolveQueue.splice(0, this.maxCount);
        this.currentUtilization = nextBatch.length;
        for (const resolve of nextBatch) {
          resolve();
        }

        if (nextBatch.length === 0 && this.interval !== null) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }, this.timeout);
    }

    if (this.currentUtilization < this.maxCount) {
      this.currentUtilization++;
      return Promise.resolve();
    } else {
      return new Promise((resolve) => {
        this.resolveQueue.push(resolve);
      });
    }
  }
}

export type ParameterType<T> = T extends (arg: infer R) => unknown ? R : never;
export type ReturnType<T> = T extends (...args: unknown[]) => infer R
  ? R
  : never;

export interface Logger {
  log: (...args: unknown[]) => void;
  dir: (...args: Array<{ [k: string]: unknown }>) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
}

export const consoleLogger: Logger = {
  log: console.log,
  dir: console.dir,
  warn: console.warn,
  error: console.error,
  debug: (...args: unknown[]) => {
    if (process.env.DEBUG) {
      console.debug(...args);
    }
  },
};

export const mapToObject = <T>(m: Map<string, T>): { [key: string]: T } => {
  return Object.fromEntries(m.entries());
};

export const objectToMap = <T>(m: { [key: string]: T }): Map<string, T> => {
  return new Map(Object.entries(m));
};

export type RequireOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type Optional<T extends { [key: string]: unknown }> = {
  [K in keyof T]?: T[K];
};

export type WithOptional<T, U extends keyof T> = Pick<
  T,
  Exclude<keyof T, U>
> & { [K in U]?: T[K] };
export type WithNullable<T, N extends keyof T> = {
  [K in keyof T]: K extends N ? T[K] | null : T[K];
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const stringify = (a: unknown, depth = 3): string => {
  if (depth === 0) {
    return `[a]`;
  } else if (isArray(a)) {
    const inner = a.map((ax) => stringify(ax, depth - 1)).join(", ");
    return `[${inner}]`;
  } else if (isObject(a)) {
    const inner = Object.entries(a)
      .map(([k, v]) => k + ": " + stringify(v, depth - 1))
      .join(", ");
    return `{${inner}}`;
  } else if (typeof a === "string") {
    return a;
  } else {
    return JSON.stringify(a);
  }
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const setTerminatingInterval = (
  fn: () => boolean,
  interval: number,
  opts: { leading?: boolean; onError?: (err: Error) => boolean } = {}
) => {
  let instance: NodeJS.Timeout | null = null;

  const handler = () => {
    try {
      const shouldTerminate = fn();
      if (shouldTerminate && instance) {
        clearInterval(instance);
        instance = null;
      }
    } catch (err) {
      if (opts.onError) {
        const shouldTerminate = opts.onError(err as Error);
        if (shouldTerminate && instance) {
          clearInterval(instance);
          instance = null;
        }
      }
    }
  };

  instance = setInterval(handler, interval);

  if (opts.leading) {
    handler();
  }
};

type SubHandler<T> = (a: T) => void;

export const subscribe = <T, E extends string>(
  o: {
    on: (e: E, fn: SubHandler<T>) => void;
    off: (e: E, fn: SubHandler<T>) => void;
  },
  e: E,
  fn: (a: T) => boolean
) => {
  const handler = (a: T) => {
    const shouldTerminate = fn(a);
    if (shouldTerminate) {
      o.off(e, handler);
    }
  };

  o.on(e, handler);

  return {
    unsubscribe: () => {
      o.off(e, handler);
    },
  };
};

export const isReplacementDefinition = (cmd: string): boolean =>
  /replace.+:.+/.test(cmd);

export const getInitInstrument = (exchange: ExchangeLabel) => {
  const instrumentMap: Record<ExchangeLabel, string> = {
    binance: "BTCUSDT",
    "binance-spot": "BTCUSDT",
  };
  return instrumentMap[exchange];
};

export type Defined<T> = T extends undefined | null ? never : T;

export const isDefined = <T>(x: T | undefined | null): x is T =>
  x !== undefined && x !== null;

export const isBetween = (a: number, edgeA: number, edgeB: number) => {
  const lesser = Math.min(edgeA, edgeB);
  const greater = Math.max(edgeA, edgeB);
  return lesser <= a && a <= greater;
};

export const splitMajorMinorVersion = (
  v: string | number
): [number, number] => {
  const versionParts = v.toString().split(".");

  const major = parseInt(versionParts[0] ?? "0");
  const minor = parseInt(versionParts[1] ?? "0");
  return [major, minor];
};

export class PromiseTimeout extends Error {
  constructor(message?: string) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PromiseTimeout);
    }
  }
}

export function promiseWithTimeout<T>(
  timeoutMs: number,
  promise: Promise<T> | (() => Promise<T>)
): Promise<T> {
  const mainPromise = typeof promise === "function" ? promise() : promise;
  const timeout = new Promise((_resolve, reject) =>
    setTimeout(
      () =>
        reject(
          new PromiseTimeout(`Promise timeout: ${timeoutMs / 1000} seconds`)
        ),
      timeoutMs
    )
  ) as Promise<never>;
  return Promise.race([mainPromise, timeout]);
}

export function getErrorMessage(e: unknown): string {
  if (!e) {
    return `${e}`; // null, undefined, false
  }
  const typeOf = typeof e;
  switch (typeOf) {
    case "string": {
      return e as string;
    }
    case "function": {
      return `[Function]`;
    }
    case "symbol": {
      return (e as symbol).toString();
    }
    case "object": {
      const err = e as Error;
      if (typeof err.message === "string") {
        return `${err.message}`;
      }
      if (typeof err.toString === "function") {
        return err.toString();
      }
      try {
        const { name } = err.constructor;
        return typeof name === "string" ? name : "[Object]";
      } catch {
        return "[Object]";
      }
    }
    default: {
      return `${e}`;
    }
  }
}
