import * as R from "ramda";
import {
  ALL_SYM,
  APP_VERSION,
  AuthRecord,
  ClassicAccount,
  ClientAuthArgs,
  ContextNotification,
  GlobalContext,
  IchibotRPC,
} from "../shared/ichibotrpc_types";
import { ExchangeLabel } from "../shared/types";
import { delay, getErrorMessage, ParameterType } from "../shared/util";
import {
  CLIENT_STATUS,
  CONTEXT_UPDATE,
  PARENT_AUTH_MODE,
  WEBSOCKET_CLOSE,
  WEBSOCKET_OPEN,
} from "./constants";
import { IchibotClientOpts } from "./ichibotclient";
import {
  classicAccountToAuth,
  getLastContextOrNull,
  isClassic,
  refreshPrompt,
} from "./methods";
import { IchibotSingle, IchibotSingleEmitter } from "./singleclient";
import { Connection, ConnectionStatus, NETWORK_STATUS } from "./types";

const CLIENT_VERSION_FULL = APP_VERSION;

const cfgIssueMsg = "This is usually due to your configuration being updated";

class ConnectionsManager {
  connections: Map<string, Connection> = new Map();
  constructor(
    private opts: IchibotClientOpts,
    private clientId: string,
    private mainContext: { context: GlobalContext | null }
  ) {}
  async getStatus(auth: ClientAuthArgs | null) {
    const result: [result: string, debugResult: string] = ["", ""];
    if (auth === null) {
      result[0] += `No login is currently loaded\n`;
      for (const v of this.connections.values()) {
        const localContext = await getLastContextOrNull(v);
        const lastKnownContext =
          localContext === null
            ? ``
            : ` - Last known instrument: ${
                localContext.currentInstrument ?? ALL_SYM
              }`;
        result[1] += `${v.account.friendlyName} - ${v.status.network}${lastKnownContext}\n`;
      }
      return result.map((x) => x.trimEnd());
    }
    result[0] += `Current login mode: ${auth.mode}\n`;
    const keys = ConnectionsManager.authToKeys(auth);
    const connKeys = new Set(this.connections.keys());
    const currentLogins = keys.map((key) => {
      connKeys.delete(key);
      return this.connections.get(key);
    });
    for (const [k, v] of currentLogins.entries()) {
      if (v === undefined) {
        result[0] += `Login [${k}] - no connection data\n`;
        continue;
      }
      const localContext = await getLastContextOrNull(v);
      const lastKnownContext =
        localContext === null
          ? ``
          : ` - Instrument: ${localContext.currentInstrument ?? ALL_SYM}`;
      result[0] += `Login [${k}] - ${v.auth.friendlyName} - ${v.status.network}${lastKnownContext}\n`;
    }
    for (const k of connKeys.values()) {
      const v = this.connections.get(k);
      if (v !== undefined) {
        const mode =
          v.account[PARENT_AUTH_MODE] === "semar" ? "semar" : "classic";
        const localContext = await getLastContextOrNull(v);
        const lastKnownContext =
          localContext === null
            ? ``
            : ` - Last known instrument: ${
                localContext.currentInstrument ?? ALL_SYM
              }`;
        result[1] += `${mode}: ${v.auth.friendlyName} - ${v.status.network}${lastKnownContext}\n`;
      }
    }
    return result.map((x) => x.trimEnd());
  }
  newConnection(account: ClassicAccount, auth: AuthRecord) {
    const emitter = new IchibotSingleEmitter();
    const p = new Promise<IchibotSingle>((resolve, reject) => {
      IchibotSingle.start(this.opts, this.clientId, account, auth, emitter)
        .then((val) => {
          try {
            const single = new IchibotSingle(val);
            resolve(single);
          } catch (e) {
            reject(e);
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
    const result = { emitter, promise: p, isPending: true, proceedHello: true };
    p.then(() => {
      result.isPending = false;
    }).catch(() => {
      result.isPending = false;
    });
    return result;
  }
  private async cleanupOldConnection(connection: Connection) {
    const { status } = connection;
    if (status.network === NETWORK_STATUS.closed) {
      connection.conn.emitter.removeAllListeners();
      return;
    }
    if (status.network === NETWORK_STATUS.closing) {
      connection.conn.emitter.removeAllListeners();
      return;
    }
    this.closeConn(connection);
    connection.conn.emitter.removeAllListeners();
  }
  private setupNewConnection(account: ClassicAccount) {
    const auth = classicAccountToAuth(account);
    const conn = this.newConnection(account, auth);
    const newC: Connection = {
      account,
      auth,
      conn,
      status: {
        network: NETWORK_STATUS.connecting,
      },
      firstSuccess: true,
    };

    conn.emitter.addListener(
      CLIENT_STATUS,
      (update: Partial<ConnectionStatus>) => {
        newC.status = R.mergeRight(newC.status, update);
      }
    );
    conn.emitter.on(WEBSOCKET_CLOSE, () => {
      if (
        newC.status.network !== "closing" &&
        newC.status.network !== "closed"
      ) {
        conn.emitter.emit(CLIENT_STATUS, { network: "reconnecting" });
      }
    });
    conn.emitter.on(WEBSOCKET_OPEN, () => {
      if (
        newC.status.network !== "closing" &&
        newC.status.network !== "closed"
      ) {
        const hello = async () => {
          const is = await conn.promise;
          if (!conn.proceedHello) {
            return;
          }
          const { initLines } = this.opts.readInitFile(account.exchange);
          const result = await is.callRpc(null, "hello", {
            name: "donut",
            version: CLIENT_VERSION_FULL,
            initLines,
            context: this.mainContext.context,
            quantityMultiplier: account.quantityMultiplier,
          });
          this.opts.logger.debug(result);
          if (
            newC.status.network !== "closing" &&
            newC.status.network !== "closed"
          ) {
            conn.emitter.emit(CLIENT_STATUS, { network: "connected" });
          }
          if (newC.firstSuccess) {
            newC.firstSuccess = false;
            if (!result.instanceStarted) {
              this.opts.logger.log(
                `[${account.friendlyName.substring(
                  0,
                  6
                )}] Resumed an existing bot session. Initlines were not re-evaluated.`
              );
            }
          }
        };
        hello().catch((e: unknown) => {
          const msg = getErrorMessage(e);
          const shortKey = ClientManager.generateShortKey(
            account.apiKey,
            account.subAccount
          );
          this.opts.logger.error(`Failed to login ${shortKey} due to ${msg}`);
        });
      }
    });
    conn.emitter.on(
      CONTEXT_UPDATE,
      (context: ContextNotification["params"]) => {
        this.mainContext.context = context;
        refreshPrompt(this.opts, context);
      }
    );
    return newC;
  }
  private addConnection(account: ClassicAccount) {
    const key = ClientManager.generateKey(
      account.exchange,
      account.apiKey,
      account.subAccount
    );
    const oldConnection = this.connections.get(key);
    const newConnection = this.setupNewConnection(account);
    this.connections.set(key, newConnection);
    if (oldConnection !== undefined) {
      const connFName = oldConnection.account.friendlyName;
      const currFName = account.friendlyName;
      if (connFName !== currFName) {
        this.opts.logger.log(
          `The account for the connection ${connFName} will be called ${currFName} for the new connection. ${cfgIssueMsg}`
        );
      }
      this.cleanupOldConnection(oldConnection);
    }
  }
  static authToKeys(auth: ClientAuthArgs): string[] {
    if (isClassic(auth)) {
      const classicAccount = auth as ClassicAccount;
      const key = ClientManager.generateKey(
        classicAccount.exchange,
        classicAccount.apiKey,
        classicAccount.subAccount
      );
      return [key];
    } else if (auth.mode === "semar") {
      const { exchange, accounts } = auth;
      return accounts.map((account) => {
        const { apiKey, subAccount } = account;
        return ClientManager.generateKey(exchange, apiKey, subAccount);
      });
    }
    return [];
  }
  addConnections(auth: ClientAuthArgs) {
    if (isClassic(auth)) {
      const classicAccount = auth as ClassicAccount;
      this.addConnection(classicAccount);
    } else if (auth.mode === "semar") {
      const { exchange, accounts, friendlyName } = auth;
      for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];
        const {
          apiKey,
          apiSecret,
          passphrase,
          subAccount,
          quantityMultiplier,
        } = account;
        const cAuth: ClassicAccount = {
          mode: undefined,
          exchange,
          apiKey,
          apiSecret,
          passphrase,
          subAccount,
          friendlyName: `${i}-${friendlyName}`,
          quantityMultiplier,
          [PARENT_AUTH_MODE]: auth.mode,
        };
        this.addConnection(cAuth);
      }
    } else {
      this.opts.logger.warn(`mode ${auth.mode} is not implemented`);
    }
  }
  removeConnections(
    auth: ClientAuthArgs,
    skipBye = false
  ): Promise<(boolean | null)[]> {
    const keys = ConnectionsManager.authToKeys(auth);
    const results = [];
    for (const key of keys) {
      const c = this.connections.get(key);
      if (c) {
        c.conn.emitter.removeAllListeners(CONTEXT_UPDATE);
        if (skipBye) {
          const res = this.closeConn(c);
          results.push(res);
        } else {
          const res = this.byeConn(c);
          results.push(res);
        }
      }
    }
    return Promise.all(results);
  }
  closeConn(c: Connection): Promise<boolean | null> {
    const { conn } = c;
    c.status = R.mergeRight(c.status, { network: "closing" as const });
    const p = conn.promise
      .then(async (is) => {
        is.close();
        return true;
      })
      .catch(() => null);
    if (conn.isPending) {
      conn.proceedHello = false;
      return p;
    }
    return p;
  }
  byeConn(c: Connection): Promise<boolean | null> {
    const { conn } = c;
    c.status = R.mergeRight(c.status, { network: "closing" as const });
    if (conn.isPending) {
      conn.proceedHello = false;
      IchibotSingle.connectForByeOnly(
        this.opts,
        this.clientId,
        c.account,
        c.auth
      );
      return conn.promise
        .then(async (is) => {
          is.close();
          return true;
        })
        .catch(() => null);
    }
    const p = conn.promise
      .then(async (is) => {
        try {
          return await is.bye();
        } catch (e) {
          const msg = getErrorMessage(e);
          this.opts.logger.warn(`An exit attempt caused an error: ${msg}`);
          return false;
        }
      })
      .catch(() => null);
    return p;
  }
}

type ClientManagerInternals = {
  clientId: string;
  auth: ClientAuthArgs | null;
  connMan: ConnectionsManager;
  opts: IchibotClientOpts;
  mainContext: { context: GlobalContext | null };
};

export default class ClientManager {
  private internals: ClientManagerInternals;
  constructor(
    opts: IchibotClientOpts,
    clientId: string,
    mainContext: { context: GlobalContext | null }
  ) {
    this.internals = {
      clientId,
      auth: null,
      connMan: new ConnectionsManager(opts, clientId, mainContext),
      opts,
      mainContext,
    };
  }
  static generateKey(
    exchange: ExchangeLabel,
    apiKey: string,
    subAccount?: string | null
  ) {
    return `${exchange}/${apiKey}/${subAccount ?? ""}`;
  }
  static generateShortKey(apiKey: string, subAccount?: string | null) {
    return subAccount
      ? `${apiKey.substring(0, 6)} - ${subAccount}`
      : apiKey.substring(0, 6);
  }
  getStatus() {
    return this.internals.connMan.getStatus(this.internals.auth);
  }
  setAuth(auth: ClientAuthArgs) {
    if (this.internals.auth !== null) {
      this.clearAuth();
    }
    this.internals.auth = auth;
    this.internals.connMan.addConnections(auth);
  }
  clearAuth(skipBye = false) {
    if (this.internals.auth === null) {
      return Promise.resolve([]);
    }
    const { auth } = this.internals;
    this.internals.auth = null;
    return this.internals.connMan.removeConnections(auth, skipBye);
  }

  resetContext(keys: string[]) {
    const pr = keys.map(async (key, ind) => {
      const s = this.internals.connMan.connections.get(key);
      if (s === undefined) {
        return `Could not reset context for connection ${ind}/${keys.length}: client error (this should not happen)`;
      }
      const { conn } = s;
      if (s.status.network !== NETWORK_STATUS.connected || conn.isPending) {
        return `Could not reset context for connection ${ind}/${keys.length}: client is not connected.`;
      } else {
        const is = await conn.promise;
        try {
          const p: ReturnType<IchibotRPC["rawcmd"]> = is.resetContext({});
          return p;
        } catch (e: unknown) {
          return (e as Error).message;
        }
      }
    });
    return pr;
  }

  async callRpcWithDelay<T extends keyof IchibotRPC>(
    key: string,
    delayMs: number,
    cmdNum: number,
    method: T,
    arg: Omit<ParameterType<IchibotRPC[T]>, "auth">,
    debugInfo?: string
  ): Promise<ReturnType<IchibotRPC[T]> | string> {
    await delay(delayMs);
    const s = this.internals.connMan.connections.get(key);
    if (s === undefined) {
      return `Could not send a request [${cmdNum}] to Ichibot server ${debugInfo}: client error (this should not happen)`;
    } else {
      const { conn } = s;
      if (s.status.network !== NETWORK_STATUS.connected || conn.isPending) {
        return `Could not send a request [${cmdNum}] to Ichibot server for ${debugInfo}: client is not connected.`;
      } else {
        const is = await conn.promise;
        try {
          const p: ReturnType<IchibotRPC[T]> = is.callRpc<T>(
            cmdNum,
            method,
            arg
          );
          return p;
        } catch (e: unknown) {
          return (e as Error).message;
        }
      }
    }
  }

  callRpc<T extends keyof IchibotRPC>(
    keys: string[],
    delays: number[],
    cmdNum: number,
    method: T,
    arg: Omit<ParameterType<IchibotRPC[T]>, "auth">
  ) {
    const ps: Promise<ReturnType<IchibotRPC[T]> | string>[] = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const delay = delays[i] ?? 0;
      const p = this.callRpcWithDelay<T>(
        key,
        delay,
        cmdNum,
        method,
        arg,
        `(connection ${i}/${keys.length})`
      );
      ps.push(p);
    }

    return ps;
  }
}
