import { ClientAuthArgs, GlobalContext } from "../shared/ichibotrpc_types";
import { DefaultMap } from "../shared/util";
import { IchibotClientOpts } from "./ichibotclient";

interface IsPending {
  pending: boolean;
}

type FulfilledReflection<T> = { status: "fulfilled"; value: T };
type RejectedReflection = { status: "rejected"; reason: unknown };

type Reflection<T> = FulfilledReflection<T> | RejectedReflection;

export type RawCmdResult = { success: boolean; message?: string };

interface RawCmdResult1 {
  promise: Promise<Reflection<RawCmdResult>>;
  isPending: IsPending;
}

interface RawCmdProgress {
  cmdNum: number;
  context: GlobalContext;
  cmd: string;
  results: RawCmdResult1[];
}

export class RawCmdResolver {
  private mp = new DefaultMap<string, RawCmdProgress[]>(() => []);
  constructor(private opts: IchibotClientOpts, private auth: ClientAuthArgs) {}
  async checkStatus(cmdN: string) {
    const shortN = RawCmdResolver.shortNum(cmdN);
    let rcp = this.mp.get(shortN);
    if (cmdN.length > 4) {
      const len = cmdN.length;
      rcp = rcp.filter(({ cmdNum }) => {
        const short = cmdNum.toString().slice(-len);
        return short === cmdN;
      });
    }
    for (const prog of rcp) {
      const { cmdNum, context, cmd, results } = prog;
      const instrument = context.currentInstrument ?? "*";
      let pending = 0;
      let sent = 0;
      let fail = 0;
      for (const rc of results) {
        const { promise, isPending } = rc;
        if (isPending.pending) {
          pending++;
        } else {
          const r = await promise;
          if (r.status === "fulfilled") {
            if (r.value.success) {
              sent++;
            } else {
              fail++;
            }
          } else if (r.status === "rejected") {
            fail++;
          }
        }
      }
      const { friendlyName } = this.auth;
      this.opts.logger.dir({
        friendlyName,
        cmdNum,
        instrument,
        cmd,
        pending,
        sent,
        fail,
      });
    }
  }
  async resolve(
    cmdNum: number,
    context: GlobalContext,
    cmd: string,
    results: Promise<RawCmdResult>[]
  ): Promise<RawCmdResult[]> {
    const prog: RawCmdProgress = {
      cmdNum,
      context,
      cmd,
      results: RawCmdResolver.processResults(results),
    };
    const shortN = RawCmdResolver.shortNum(cmdNum);
    this.mp.get(shortN).push(prog);
    const promises = prog.results.map(({ promise }) => promise);
    const allSettled = await Promise.all(promises);
    return allSettled.map((refl) => {
      if (refl.status === "fulfilled") {
        return refl.value;
      }
      if (refl.status === "rejected") {
        return { success: false, message: `${refl.reason}` };
      }
      return { success: false, message: "unknown" };
    });
  }
  static shortNum(cmdNum: number | string) {
    return cmdNum.toString().slice(-4);
  }
  static processResults(results: Promise<RawCmdResult>[]): RawCmdResult1[] {
    const rawCmdResults: RawCmdResult1[] = results.map((result) => {
      const isPending = { pending: true };
      const promise: Promise<Reflection<RawCmdResult>> = result
        .then((v) => {
          isPending.pending = false;
          return {
            status: "fulfilled",
            value: v,
          } as FulfilledReflection<RawCmdResult>;
        })
        .catch((error) => {
          isPending.pending = false;
          return { status: "rejected", reason: error } as RejectedReflection;
        });
      return { promise, isPending };
    });
    return rawCmdResults;
  }
}
